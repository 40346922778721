import React, { useState } from "react"
import Layout from "../components/Layout"
import {
  ContactForm,
  LeftCol,
  Mail,
  Max,
  Message,
  MessageField,
  Name,
  RightCol,
  RowWrapper,
  SubmitButton,
} from "../components/contact/contact"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

const errorMessage =
  "Etwas ist schiefgelaufen. Bitte sende uns stattdessen eine E-Mail an webmaster@altburgund.de"

export default function ContactPage() {
  const [state, setState] = useState({
    name: "",
    message: "",
    email: "",
    mailSent: false,
    // snackbar
    snackOpen: false,
    snackMessage: "",
  })

  function handleInputChange(event) {
    event.persist()
    setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }

  function handleSubmit(event) {
    event.preventDefault()

    // check if all fields are filled
    if (!state.email || !state.name || !state.message) {
      setState(prevState => ({
        ...prevState,
        snackMessage: "Bitte fülle alle Felder aus",
        snackOpen: true,
      }))
      return
    }

    // create formData element holding the input from the contact form
    const formData = new FormData()
    formData.append("name", state.name) // sender name
    formData.append("email", state.email) // sender email
    formData.append("message", state.message) // message

    // API for sending emails
    /* currently sends always to Luis.Hocke@gmail.com */
    const API_PATH = "https://altburgund.de/api/mail.php"

    fetch(API_PATH, {
      method: "post",
      body: formData,
    })
      .then(result => {
        console.log(result)
        if (result.status === 200) {
          setState(prevState => ({
            ...prevState,
            email: "",
            name: "",
            message: "",
            snackMessage: "Nachricht gesendet",
            snackOpen: true,
          }))
        } else {
          setState(prevState => ({
            ...prevState,
            snackMessage: errorMessage,
            snackOpen: true,
          }))
        }
      })
      .catch(err => {
        console.error(err)
        // do not clear fields in case of error
        // setState({
        //   email: "",
        //   name: "",
        //   message: "",
        // })
        setState(prevState => ({
          ...prevState,
          snackMessage: errorMessage,
          snackOpen: true,
        }))
      })
  }

  // close snackbar
  function handleSnackClose(event, reason) {
    if (reason === "clickaway") {
      return
    }
    setState(prevState => ({
      ...prevState,
      snackOpen: false,
    }))
  }

  return (
    <Layout title="Gau Alt-Burgund | Kontakt" description="Kontaktformular">
      <Max>
        <ContactForm>
          <form onSubmit={handleSubmit}>
            <RowWrapper>
              <LeftCol>
                <label htmlFor="name">Name</label>
              </LeftCol>
              <RightCol>
                <Name
                  type="text"
                  name="name"
                  placeholder="Dein Name"
                  value={state.name}
                  onChange={handleInputChange}
                />
              </RightCol>
            </RowWrapper>

            <RowWrapper>
              <LeftCol>
                <label htmlFor="email">E-Mail</label>
              </LeftCol>
              <RightCol>
                <Mail
                  type="email"
                  name="email"
                  placeholder="Deine Mailadresse"
                  value={state.email}
                  onChange={handleInputChange}
                />
              </RightCol>
            </RowWrapper>

            <RowWrapper>
              <LeftCol>
                <label htmlFor="message">Nachricht</label>
              </LeftCol>
              <RightCol>
                <Message
                  type="text"
                  name="message"
                  placeholder="Deine Nachricht"
                  value={state.message}
                  onChange={handleInputChange}
                />
              </RightCol>
            </RowWrapper>
            <RowWrapper>
              <LeftCol />
              <RightCol>
                <SubmitButton type="submit">
                  <span>Senden</span>
                </SubmitButton>
              </RightCol>
            </RowWrapper>
          </form>
        </ContactForm>
        <MessageField
          color={state.snackMessage === errorMessage ? "red" : "white"}
        >
          {state.snackMessage}
        </MessageField>
      </Max>
      <Snackbar
        style={{
          zIndex: "99999999",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={state.snackOpen}
        autoHideDuration={4000}
        onClose={handleSnackClose}
        ContentProps={{
          "aria-describedby": "message-id",
        }}
        message={<span id="message-id">{state.snackMessage}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={handleSnackClose}
            href={""}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Layout>
  )
}
