import styled from "styled-components"
import colors from "../../constants/colors"
import breakpoints from "../../constants/breakpoints"

export const Max = styled.div`
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
`

export const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem 0 2rem;
  margin: 0 auto 5rem auto;
  padding-top: 5%;

  button {
    margin: 0 0 0 auto;
  }
`

export const RowWrapper = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    width: 100%;
  }
`

export const LeftCol = styled.div`
  width: 25%;
  margin-top: 1rem;
  font-weight: bold;
`

export const RightCol = styled.div`
  width: 75%;
  margin-top: 6px;
  display: flex;

  @media (max-width: ${breakpoints.small}px) {
    width: 90%;
  }
`

export const Name = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
`

export const Mail = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
`

export const Message = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  height: 10rem;
`

export const SubmitButton = styled.button`
  background-color: ${colors.vcp};
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
  margin: 4px 2px;
  cursor: pointer;
  border-style: none;
`

export const MessageField = styled.div`
padding: 1rem;

  border-style: solid;
  border-width: 2px;
  border-color: ${props => props.color};
`
